import WebServiceRequest from '../Api/WebServiceRequest'
class MediaCreateFileRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Media/CreateFile`);
    }
    setParams(data) {
        super.setRequestParamDataObj(data)
    }
}

export{
    MediaCreateFileRequest
}