import WebServiceRequest from '../Api/WebServiceRequest'
class StaticPageCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class StaticPageGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class StaticPageUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class StaticPageGetByPageIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/GetByPageId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class StaticPageGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('StaticPage/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    StaticPageCreateRequest,
    StaticPageGetRequest,
    StaticPageUpdateRequest,
    StaticPageGetByPageIdRequest,
    StaticPageGetAllRequest
}