<template>
  <div class="w-full position-relative">
    <button
      class="btn btn-primary my-1 p-25"
      style="z-index: 9999999999"
      type="button"
      @click="showSourceCode = !showSourceCode"
    >
      <small> &lt;/> </small>
    </button>
    <ckeditor-nuxt
      class=""
      v-if="!showSourceCode"
      v-model="contentHolder"
      :config="editorConfig"
      @input="emitDataToParent"
    />
    <textarea
      v-if="showSourceCode"
      v-model="contentHolder"
      class="form-control"
      @input="emitDataToParent"
    ></textarea>
    <!--    <div class="d-flex align-items-center gap-3 my-1">-->
    <!--      <p class="m-0 mx-1">تعداد کلمات : {{ wordsCount }}</p>-->
    <!--      <p class="m-0 mx-1">تعداد حروف : {{ chararactersCount }}</p>-->
    <!--    </div>-->
  </div>
</template>

<script>
import ckEditorUploadAdapter from "../../libs/CkEditorUploadAdapter";
import CkeditorNuxt from "@blowstack/ckeditor-nuxt";

export default {
  emits: ["editorContent"],
  props: {
    defaultContent: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  components: {
    CkeditorNuxt,
  },
  beforeMount() {
    if (this.defaultContent) {
      this.contentHolder = this.defaultContent;
    }
    if (this.placeholder != null) {
      this.editorConfig.placeholder = this.placeholder;
    }
  },
  watch: {
    defaultContent: {
      immediate: true,
      handler(val) {
        if (val) {
          this.contentHolder = val;
        } else {
          this.contentHolder = "";
        }
      },
    },
  },

  data() {
    return {
      chararactersCount: null,
      showSourceCode: false,
      wordsCount: null,
      editorConfig: {
        language: {
          ui: "en",
          content: "ar",
        },
        extraPlugins: [this.uploader],
        imageUploadUrl: "/",
        uploadUrl: "/",
        image: {
          upload: {
            types: ["png", "jpeg"],
          },
        },
        // plugins: [Base64UploadAdapter],
        wordCount: {
          onUpdate: (stats) => {
            this.chararactersCount = stats.characters;
            this.wordsCount = stats.words;
          },
        },

        removePlugins: [
          "Title",
          "mathtype",
          "chemtype",
          "MathType",
          "ChemType",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
          ],
        },

        placeholder: "",
      },
      contentHolder: "",
    };
  },
  methods: {
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new ckEditorUploadAdapter(loader);
      };
    },
    emitDataToParent() {
      this.$emit("getEditorContent", this.contentHolder);
    },
  },
};
</script>
<style>
.ck[dir="rtl"] .ck {
  text-align: left;
}

.ck.ck-editor__editable_inline {
  direction: ltr;
  text-align: left;
}

.ck-placeholder {
  text-align: left;
  direction: ltr;
}
</style>
